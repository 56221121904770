export function useLerpedMouseState(options: { onUpdate?: () => void }) {
  const state = ref({
    mouseX: 0,
    mouseY: 0,
    x: 0,
    y: 0,
  });

  const animation = ref<number>(0);

  onMounted(function () {
    window.addEventListener('mousemove', onMouseMove);

    tick();
  });

  return {
    state,
    stop,
  };

  function stop() {
    cancelAnimationFrame(animation.value);
  }

  function onMouseMove(e: MouseEvent) {
    state.value.mouseX = e.clientX;
    state.value.mouseY = e.clientY;
  }

  function tick() {
    state.value.x = lerp(state.value.x, state.value.mouseX, 0.35);
    state.value.y = lerp(state.value.y, state.value.mouseY, 0.35);

    if (options.onUpdate) {
      options.onUpdate();
    }

    animation.value = window.requestAnimationFrame(tick);
  }

  function lerp(start: number, end: number, amt: number) {
    return (1 - amt) * start + amt * end;
  }
}
