import { default as _91slug_93FNbD9oDbfEMeta } from "/opt/render/project/src/pages/[slug].vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as _91slug_93ztW240kJr2Meta } from "/opt/render/project/src/pages/jobs/[slug].vue?macro=true";
import { default as _91slug_93nlhuyWJVo4Meta } from "/opt/render/project/src/pages/projects/[slug].vue?macro=true";
import { default as component_45stubzFO8P7pzWFMeta } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzFO8P7pzWF } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de___default",
    path: "/:slug()",
    meta: _91slug_93FNbD9oDbfEMeta || {},
    component: () => import("/opt/render/project/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/de/:slug()",
    meta: _91slug_93FNbD9oDbfEMeta || {},
    component: () => import("/opt/render/project/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr",
    path: "/fr/:slug()",
    meta: _91slug_93FNbD9oDbfEMeta || {},
    component: () => import("/opt/render/project/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index___de___default",
    path: "/",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___de___default",
    path: "/offene-stellen/:slug()",
    meta: _91slug_93ztW240kJr2Meta || {},
    component: () => import("/opt/render/project/src/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___de",
    path: "/de/offene-stellen/:slug()",
    meta: _91slug_93ztW240kJr2Meta || {},
    component: () => import("/opt/render/project/src/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___fr",
    path: "/fr/postes-vacants/:slug()",
    meta: _91slug_93ztW240kJr2Meta || {},
    component: () => import("/opt/render/project/src/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___de___default",
    path: "/referenzen/:slug()",
    meta: _91slug_93nlhuyWJVo4Meta || {},
    component: () => import("/opt/render/project/src/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___de",
    path: "/de/referenzen/:slug()",
    meta: _91slug_93nlhuyWJVo4Meta || {},
    component: () => import("/opt/render/project/src/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___fr",
    path: "/fr/projets/:slug()",
    meta: _91slug_93nlhuyWJVo4Meta || {},
    component: () => import("/opt/render/project/src/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/de-CH-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/fr-CH-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  }
]