import gsap from 'gsap';

export function useImageAppear() {
  const page = document.querySelector('.app > main:not(.fade-leave-active)');

  const elements = Array.from(
    page?.querySelectorAll('[data-image-appear]') ?? [],
  );

  const observer = new IntersectionObserver(onObserve, {
    rootMargin: '-25% 0px 0px 0px',
    threshold: 0.25,
  });

  function onObserve(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.target) {
        onIntersect(entry.target);
      }
    });
  }

  function onIntersect(entry: Element) {
    observer.unobserve(entry);

    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.out',
        duration: 0.75,
      },
    });

    let delay = 0;
    const delayAttr = entry.getAttribute('data-image-appear-delay');
    const axis = entry.getAttribute('data-image-appear-axis') ?? 'vertical';

    if (delayAttr) {
      delay = Number(delayAttr);
    }

    if (axis === 'vertical') {
      tl.fromTo(
        entry,
        {
          clipPath: 'inset(100% 0% 0% 0%)',
          opacity: 1,
        },
        {
          clipPath: 'inset(0% 0% 0% 0%)',
          opacity: 1,
          delay,
        },
      ).fromTo(
        entry.children,
        {
          yPercent: 10,
        },
        {
          yPercent: 0,
          delay,
        },
        '<',
      );
    } else if (axis === 'horizontal') {
      tl.fromTo(
        entry,
        {
          clipPath: 'inset(0% 100% 0% 0%)',
          opacity: 1,
        },
        {
          clipPath: 'inset(0% 0% 0% 0%)',
          opacity: 1,
          delay,
        },
      ).fromTo(
        entry.children,
        {
          xPercent: 10,
        },
        {
          xPercent: 0,
          delay,
        },
        '<',
      );
    }
  }

  for (let i = 0; i < elements.length; i++) {
    const el = elements[i];

    observer.observe(el);
    setInitialState(el);
  }

  function setInitialState(el: Element) {
    gsap.set(el, {
      opacity: 0,
    });
  }
}
