<template>
  <nav
    class="lang-switch"
    :aria-label="t('ariaLabelNavigation')"
    :class="{ '_hidden-vs': !isNavOpen }"
  >
    <p class="_visually-hidden">
      {{ t('infoCurrentLanguage') }}
    </p>
    <ul
      v-if="currentPage"
      class="list text-md"
      :aria-label="t('ariaLabelList')"
    >
      <li
        v-for="it in currentPage.allTranslatedSlugs"
        :key="`${it.locale}-${it.value}`"
        class="item"
        :class="{ '-hidden': locale !== it.locale }"
      >
        <a
          :lang="String(it.locale)"
          :hreflang="String(it.locale)"
          :title="t('showPageInLang')"
          class="link text-md"
          :href="resolveLangRoute(it)"
        >
          <span class="icon">
            <img src="/icons/globe.svg" class="image" alt="Globe icon" />
          </span>
          <span class="text">
            {{ it.locale }}
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<i18n lang="yaml">
fr:
  ariaLabelNavigation: Navigation linguistique
  ariaLabelList: Changer de langue
  infoCurrentLanguage: Navigation linguistique. La langue actuelle est l'anglais
  showPageInLang: Afficher cette newsPage en français
de:
  ariaLabelNavigation: Sprachnavigation
  ariaLabelList: Sprache wechseln
  infoCurrentLanguage: Sprachnavigation. Die aktuelle Sprache ist deutsch.
  showPageInLang: Diese Seite auf Deutsch anzeigen
</i18n>

<script setup lang="ts">
import { getRouteObject } from '~/datocms/getRouteObject';

defineProps<{
  isNavOpen: boolean;
}>();

const { localeProperties, locale, t } = useI18n();
const { resolve } = useRouter();

const app = useNuxtApp();

const { data } = await useAsyncGql({
  operation: 'GetTranslatedSlugs',
  variables: {
    locale: localeProperties.value.siteLocale,
  },
});

const allRoutes = [
  ...(data.value?.allPages ?? []),
  ...(data.value?.allHorizontalPages ?? []),
  ...(data.value?.allJobPosts ?? []),
  ...(data.value?.allProjects ?? []),
];

const currentPage = computed(function () {
  if (!app._route.params.slug) {
    return allRoutes.find((it) => it.slug === 'home');
  }

  return allRoutes.find((it) => it.translatedSlug === app._route.params.slug);
});

function resolveLangRoute(translatedSlug: {
  value?: string | null;
  locale?: string | null;
}) {
  const targetRoute = getRouteObject({
    slug: translatedSlug.value!,
    pageType: currentPage.value?.__typename ?? '',
    locale: translatedSlug.locale ?? 'de',
  });

  return targetRoute ? resolve(targetRoute).href : '/';
}
</script>

<style scoped lang="scss">
.lang-switch {
  > .list {
    width: 3.125rem;

    @media (--vs) {
      height: 1.5rem;
    }

    @media (--vl) {
      height: 1.75rem;
    }

    > .item {
      position: absolute;

      @media (--vs) {
        right: 0;
      }

      &.-hidden {
        opacity: 0;
      }

      &:not(.-hidden) {
        pointer-events: none;
        z-index: -1;
      }

      > .link {
        text-transform: capitalize;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;

        @media (--vl) {
          color: currentColor;
        }
        @media (--vs) {
          color: var(--color-white);
        }

        &.-link-exact-active {
          font-weight: bold;
        }

        > .icon {
          width: 1rem;
          height: 1rem;
          flex-shrink: 0;

          > .image {
            object-fit: contain;
            width: 100%;
            height: 100%;
            filter: invert(1);
          }
        }
      }
    }
  }

  @media (--vs) {
    position: fixed;
    inset: auto var(--base-component-padding-inline) 1.5rem auto;
    z-index: 11;
    filter: invert(1);
  }
}
</style>
