import type { RouteLocationRaw } from 'vue-router';

export function getRouteObject(data: {
  slug: string;
  pageType: string;
  locale: string;
}) {
  const route: RouteLocationRaw = {
    name: '',
    params: {
      slug: data.slug,
    },
  };

  if (data.slug === 'home') {
    route.name = `index___${data.locale}`;
    route.params = undefined;
  } else if (data.pageType === 'JobPostRecord') {
    route.name = `jobs-slug___${data.locale}`;
  } else if (data.pageType === 'PageRecord') {
    route.name = `slug___${data.locale}`;
  } else if (data.pageType === 'ProjectRecord') {
    route.name = `projects-slug___${data.locale}`;
  }

  return route;
}
