<template>
  <nav
    v-if="mainNavigation"
    id="main-navigation"
    :aria-expanded="isOpen"
    class="main-navigation"
    role="navigation"
    :aria-label="t('ariaLabelMainNavigation')"
  >
    <ul v-if="navLinks.length" class="list -first-level">
      <li v-for="item in navLinks" :key="`nav-link-${item.id}`" class="item">
        <NavLink class="navlink text-md-vl text-2xl-vs" :data="item" />
      </li>
    </ul>
    <p class="about _hidden-vl">
      <a
        v-if="$SiteInfo.email"
        class="link text-lg -email"
        :href="`mailto:${$SiteInfo.email}`"
        >{{ $SiteInfo.email }}</a
      >
      <a
        v-if="$SiteInfo.telephone"
        class="link text-lg -phone"
        :href="`tel:${$SiteInfo.telephone}`"
        >{{ $SiteInfo.telephone }}</a
      >
    </p>
  </nav>
</template>

<i18n lang="yaml">
fr:
  ariaLabelMainNavigation: Navigation principale
de:
  ariaLabelMainNavigation: Hauptnavigation
</i18n>

<script setup lang="ts">
import type { GetMainNavigationQuery } from '#gql';
import NavLink from '~/components/site-header/NavLink.vue';

defineProps<{
  isOpen: boolean;
}>();

const { localeProperties, t } = useI18n();

const { data } = await useAsyncGql({
  operation: 'GetMainNavigation',
  variables: {
    locale: localeProperties.value.siteLocale,
  },
});

const mainNavigation = computed(function () {
  return (data.value as GetMainNavigationQuery)?.mainNavigation;
});

const navLinks = computed(function () {
  if (mainNavigation.value) {
    return mainNavigation.value.navLinks ?? [];
  }

  return [];
});
</script>

<style scoped lang="scss">
.main-navigation {
  @media (--vs) {
    position: fixed;
    inset: 0;
    width: 100%;
    max-width: 100vw;
    display: flex;
    padding: 1.5rem var(--base-component-padding-inline);
    color: var(--color-black);
    flex-direction: column;
    justify-content: space-between;
    gap: 5.5rem;
    background: var(--color-alabaster-100);
    padding-top: var(--site-header-height);

    &[aria-expanded='true'] {
      height: 100%;
      max-height: 100lvh;
      overflow-y: auto;

      &::after {
        content: '';
        width: 100%;
        height: calc(var(--site-header-height) - 0.5rem);
        position: fixed;
        inset: 0 0 auto 0;
        //background-color: blue;
        background-color: var(--color-alabaster-100);
        z-index: 1;
      }
    }

    &[aria-expanded='false'] {
      pointer-events: none;
      visibility: hidden;
    }

    > .toggle {
      position: absolute;
    }

    > .list {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      flex-grow: 1;
    }
  }

  > .list {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--vs) {
      gap: 4rem;
      padding-top: 5lvh;
    }

    @media (--vl) {
      gap: 3.5rem;
    }
  }

  > .about {
    > .link {
      display: block;
      color: var(--color-black);
      padding: 0.5rem 0;

      &.-phone {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}
</style>
