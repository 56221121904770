<template>
  <a
    v-if="link.linkType === 'external'"
    class="link"
    :href="String(link.externalUrl)"
    :target="link.openLinkInNewWindow ? '_blank' : '_self'"
  >
    {{ link.linkText }}
    <span class="arrow"></span>
  </a>
  <a
    v-else-if="link.linkType === 'email'"
    class="link"
    :href="`mailto:${link.emailUrl}`"
    :target="'_blank'"
  >
    {{ link.linkText }}
    <span class="arrow"></span>
  </a>
  <a
    v-else-if="link.linkType === 'document'"
    class="link"
    :href="link.document?.url ?? '#'"
    :target="'_blank'"
  >
    {{ link.linkText }}
    <span class="arrow"></span>
  </a>
  <NuxtLink
    v-else-if="link.linkType === 'internal' && link.internalUrl"
    class="link"
    :to="
      getRoute(
        String(link.internalUrl.translatedSlug),
        link.internalUrl.__typename,
      )
    "
    :target="link.openLinkInNewWindow ? '_blank' : '_self'"
  >
    {{ link.linkText }}
    <span class="arrow"></span>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { LinkFragment } from '#gql';
import { getRouteObject } from '~/datocms/getRouteObject';

const { locale } = useI18n();

defineProps<{
  link: LinkFragment;
}>();

function getRoute(slug: string, __typename: string) {
  return getRouteObject({ slug, pageType: __typename, locale: locale.value });
}
</script>

<style scoped lang="scss"></style>
