<template>
  <p v-if="$SiteInfo.email" class="contact-email">
    <a class="link" :href="`mailto:${$SiteInfo.email}`">{{
      $SiteInfo.email
    }}</a>
  </p>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
