<template>
  <nav id="footer-navigation" class="footer-navigation">
    <ul v-if="navLinks.length" class="list -first-level">
      <li v-for="item in navLinks" :key="`nav-link-${item.id}`" class="item">
        <NavLink class="navlink text-xl-vl text-2xl-vs" :data="item" />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import NavLink from '~/components/site-header/NavLink.vue';
import type { GetMainNavigationQuery } from '#gql';

const { localeProperties } = useI18n();

const { data } = await useAsyncGql({
  operation: 'GetMainNavigation',
  variables: {
    locale: localeProperties.value.siteLocale,
  },
});

const mainNavigation = computed(function () {
  return (data.value as GetMainNavigationQuery).mainNavigation;
});

const navLinks = computed(function () {
  if (mainNavigation.value) {
    return mainNavigation.value.navLinks ?? [];
  }

  return [];
});
</script>

<style scoped lang="scss">
.footer-navigation {
  > .list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
</style>
