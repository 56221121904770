import { useLerpedMouseState } from '~/utils/useLerpedMouseState';
import { useHoveredElement } from '~/utils/useHoveredElement';
import { useViewportSize } from '~/utils/useViewportSize';

export function useCustomCursor() {
  const preferredMotion = usePreferredReducedMotion();
  const { isVs } = useViewportSize();

  const el = ref<HTMLElement | null>(null);

  const { state, stop } = useLerpedMouseState({ onUpdate });

  onMounted(function () {
    el.value = getCursorElement();

    if (isVs.value || preferredMotion.value === 'reduce' || isTouchDevice()) {
      stop();

      if (el.value) {
        el.value.style.display = 'none';
        el.value.style.visibility = 'hidden';
      }

      return;
    }

    useHoveredElement({
      selectors: [
        'a',
        '.teaser-featured-projects .project',
        '.teaser-projects .project',
      ],
      onUpdate: onHoveredElementUpdate,
    });

    document.body.appendChild(el.value);
  });

  onUnmounted(function () {
    if (el.value) {
      document.body.removeChild(el.value);

      stop();
    }
  });

  function onUpdate() {
    if (el.value) {
      el.value.style.top = `${state.value.y}px`;
      el.value.style.left = `${state.value.x}px`;
    }
  }

  function onHoveredElementUpdate(hoveredElement: HTMLElement | null) {
    if (hoveredElement) {
      const type = getCursorTypeFromElement(hoveredElement);

      updateCursor(type);
    } else {
      updateCursor(null);
    }
  }

  function updateCursor(type: string | null) {
    if (!el.value) {
      return;
    }

    if (type === null) {
      el.value.setAttribute('data-active-cursor', 'null');
    } else {
      el.value.setAttribute('data-active-cursor', type);
    }
  }
}

function getCursorElement() {
  const div = document.createElement('div');

  div.classList.add('custom-cursor');
  div.setAttribute('role', 'presentation');
  div.innerHTML = `
    <div class="circle"></div>
  `;

  return div;
}

function getCursorTypeFromElement(el: HTMLElement) {
  if (el.hasAttribute('data-list-anchor')) {
    return 'list-anchor';
  } else if (el.classList.contains('teaser-default')) {
    return 'teaser';
  } else if (el.classList.contains('project')) {
    return 'project';
  } else if (el instanceof HTMLAnchorElement) {
    return 'anchor';
  }

  return null;
}

function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
}
