export type SiteHeaderInjection = {
  setSiteHeader: (el: HTMLElement) => void;
  changeBackground: (background: 'opaque' | 'transparent') => void;
};

export function useSiteHeader(): SiteHeaderInjection {
  const siteHeaderEl = ref<HTMLElement | null>(null);

  return { setSiteHeader, changeBackground };

  function setSiteHeader(el: HTMLElement) {
    siteHeaderEl.value = el;
  }

  function changeBackground(background: 'opaque' | 'transparent') {
    if (!siteHeaderEl.value) {
      return;
    }

    siteHeaderEl.value.setAttribute('data-background', background);
  }
}
