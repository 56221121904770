import gsap from 'gsap';

export function useHoveredElement(options: {
  selectors: string[];
  onUpdate?: (hoveredElement: HTMLElement | null) => void;
}) {
  const route = useRoute();

  const elements = ref<HTMLElement[]>([]);

  const state = ref<{
    currentHoveredElement: null | HTMLElement;
  }>({
    currentHoveredElement: null,
  });

  const hasHover = computed(function () {
    return state.value.currentHoveredElement !== null;
  });

  watch(
    () => route.path,
    async function () {
      reset();

      await useWait(1000);
      evaluate();
    },
  );

  reset();
  evaluate();

  return { hasHover, state };

  function reset() {
    toggleMouseEvents('remove');

    elements.value = [];
    state.value.currentHoveredElement = null;

    onStateUpdate();
  }

  function evaluate() {
    options.selectors.forEach(function (s) {
      const els = gsap.utils.toArray(s) as HTMLElement[];

      els.forEach((el) => elements.value.push(el));
    });

    toggleMouseEvents('add');
  }

  function toggleMouseEvents(event: 'remove' | 'add') {
    for (let i = 0; i < elements.value.length; i++) {
      const el = elements.value[i];

      if (event === 'remove') {
        el.removeEventListener('mouseenter', onElMouseEnter);
        el.removeEventListener('mouseleave', onElMouseLeave);
      } else {
        el.addEventListener('mouseenter', onElMouseEnter);
        el.addEventListener('mouseleave', onElMouseLeave);
      }
    }
  }

  function onElMouseEnter(event: MouseEvent) {
    state.value.currentHoveredElement = event.target as HTMLElement;

    onStateUpdate();
  }

  function onElMouseLeave(event: MouseEvent) {
    if (event.target === state.value.currentHoveredElement) {
      state.value.currentHoveredElement = null;
    }

    onStateUpdate();
  }

  function onStateUpdate() {
    if (options.onUpdate) {
      options.onUpdate(state.value.currentHoveredElement);
    }
  }
}
