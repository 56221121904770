import RichtextSocial from '~/components/richtext/RichtextSocial.vue';

// @ts-ignore
export default function renderBlock({ record }) {
  if (record.__typename === 'RichtextSocialRecord') {
    return h(RichtextSocial, { data: record });
  }

  return null;
}
