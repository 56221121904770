<template>
  <p v-if="$SiteInfo.email" class="contact-phone">
    <a class="link" :href="`tel:${$SiteInfo.telephone}`">{{
      $SiteInfo.telephone
    }}</a>
  </p>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
