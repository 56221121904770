<template>
  <div class="app">
    <SiteHeader />
    <NuxtPage />
    <SiteFooter />
  </div>
</template>

<script setup lang="ts">
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import gsap from 'gsap';
import type { TranslationPanelFragment, SiteInfoFragment } from '#gql';
import { useSiteHeader } from '~/utils/useSiteHeader';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const nuxtApp = useNuxtApp();

const { localeProperties } = useI18n();

const { data: dataSiteInfo } = await useAsyncGql({
  operation: 'GetSiteInfo',
  variables: {
    locale: localeProperties.value.siteLocale,
  },
});

if (dataSiteInfo.value?.siteInfo) {
  const SiteInfo = dataSiteInfo.value.siteInfo as SiteInfoFragment;

  nuxtApp.provide('SiteInfo', SiteInfo);
}

const { data: dataTranslationPanel } = await useAsyncGql({
  operation: 'GetTranslationPanel',
});

if (dataTranslationPanel.value && dataTranslationPanel.value) {
  const TranslationPanel = dataTranslationPanel.value
    ?.translationPanel as TranslationPanelFragment;

  nuxtApp.provide('TranslationPanel', TranslationPanel);
}

useCustomCursor();

nuxtApp.provide('SiteHeader', useSiteHeader());
</script>

<style lang="scss">
.app {
  margin: 0 var(--site-outer-margin, 0);

  // horizontal layout has a special footer, prevent default footer from flashing
  &:has(.horizontal-layout) {
    .site-footer {
      @media (--vl) {
        display: none;
      }
    }
  }
}
</style>
