<template>
  <div class="error-page">
    <div v-if="error.statusCode === 404" class="error404">
      <div class="inner">
        <p class="eyebrow text-md">404</p>
        <h1 class="title">
          <span class="line line1 text-4xl">{{ t('titleLine1') }}</span
          ><br />
          <span class="line line2 text-4xl-display">{{ t('titleLine2') }}</span>
        </h1>
        <p class="text text-lg">{{ t('text') }}</p>
        <button class="button button-primary" @click="handleError()">
          {{ t('backToHome') }}
          <span class="arrow"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<i18n lang="yaml">
fr:
  titleLine1: Mauvaise
  titleLine2: direction ?
  text: La page que tu cherches n'existe pas. Regarde donc encore une fois un peu sur notre site.
  backToHome: Retour à home
de:
  titleLine1: Falsch
  titleLine2: abgebogen?
  text: Die Seite, die du suchst, gibt es nicht. Schau dich doch nochmals ein wenig auf unserer Seite um.
  backToHome: Zurück zu Home
</i18n>

<script setup lang="ts">
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import gsap from 'gsap';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const error = useError();
const { t } = useI18n();

function handleError() {
  clearError();

  window.location.href = '/';
}
</script>

<style scoped lang="scss">
.error-page {
  > .error404 {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100svh;
    padding: var(--base-component-padding);
    display: flex;
    flex-direction: column;

    > .inner {
      margin: auto;
      width: fit-content;

      @media (--vs) {
        > .title > .line2 {
          transform: translateX(2rem);
        }
      }

      @media (--vl) {
        > .eyebrow,
        > .title > .line1 {
          transform: translateX(-3.8rem);
        }

        > .text,
        > .button,
        > .title > .line2 {
          transform: translateX(3.8rem);
        }
      }

      > .title {
        > .line {
          display: inline-block;
        }
      }

      > .eyebrow {
        @media (--vs) {
          padding-bottom: 2.5rem;
        }

        @media (--vl) {
          padding-bottom: 0.5rem;
        }
      }

      > .text {
        max-width: 38ch;

        @media (--vs) {
          padding: 2.5rem 0;
        }

        @media (--vl) {
          padding: 3rem 0;
        }
      }
    }
  }
}
</style>
