import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["HeaderHero","ImageWithCaption","Link","LinkPage","LinkProject","LinkHorizontalPage","LinkJobPost","ListItem","ContentText","ContentTextGreeting","ContentTeam","ImagePortrait","ImageLandscape","ContentImageMood","ContentImageWall","ImageTwoPortrait","ImageMasonry","ContentList","ContentContactPerson","TeaserAboutUs","TeaserDefault","TeaserProjects","TeaserFeaturedProjects","TeaserHighlight","TeaserList","PageMeta","HorizontalPageMeta","ProjectPageMeta","Project","ResponsiveImage","SEO","TeamMember","GetHorizontalPageByTranslatedSlug","GetJobPostByTranslatedSlug","GetMainNavigation","NavLink","GetPageByTranslatedSlug","GetProjectByTranslatedSlug","GetSiteInfo","SiteInfo","SocialLink","GetTranslatedSlugs","GetTranslationPanel","TranslationPanel"]}
export const GqlGetHorizontalPageByTranslatedSlug = (...params) => useGql()('GetHorizontalPageByTranslatedSlug', ...params)
export const GqlGetJobPostByTranslatedSlug = (...params) => useGql()('GetJobPostByTranslatedSlug', ...params)
export const GqlGetMainNavigation = (...params) => useGql()('GetMainNavigation', ...params)
export const GqlGetPageByTranslatedSlug = (...params) => useGql()('GetPageByTranslatedSlug', ...params)
export const GqlGetProjectByTranslatedSlug = (...params) => useGql()('GetProjectByTranslatedSlug', ...params)
export const GqlGetSiteInfo = (...params) => useGql()('GetSiteInfo', ...params)
export const GqlGetTranslatedSlugs = (...params) => useGql()('GetTranslatedSlugs', ...params)
export const GqlGetTranslationPanel = (...params) => useGql()('GetTranslationPanel', ...params)