<template>
  <NuxtLink v-if="route" class="nav-link" :to="route">{{ title }} </NuxtLink>
</template>

<script setup lang="ts">
import type { NavLinkFragment } from '#gql';
import { getRouteObject } from '~/datocms/getRouteObject';

const { locale } = useI18n();

const props = defineProps<{
  data: NavLinkFragment;
}>();

const route = computed(function () {
  if (!props.data.link) {
    return null;
  }

  return getNavRoute(props.data.link);
});

const title = computed(function () {
  if (props.data.link && 'title' in props.data.link) {
    return props.data.link.title ?? '';
  }

  return '';
});

function getNavRoute(link: NavLinkFragment['link']) {
  if (!link || !link.allTranslatedSlugs) {
    return null;
  }

  const translatedRoute = link.allTranslatedSlugs.find(
    (it) => it.locale === locale.value,
  );

  if (translatedRoute && translatedRoute.locale && translatedRoute.value) {
    return getRouteObject({
      pageType: link.__typename,
      locale: translatedRoute.locale,
      slug: translatedRoute.value,
    });
  }

  return '/';
}
</script>

<style scoped lang="scss">
.nav-link {
  color: currentColor;
  outline-offset: 0.25rem;
}
</style>
